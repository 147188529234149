<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Remove From Pipeline</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleRemoveContactPipeline" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Pipeline</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.pipeline }">
                                    <Field autocomplete="off" name="pipeline" v-model="form.pipeline_id" rules="required">
                                        <multiselect
                                            v-model="form.pipeline_id"
                                            label="title"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="pipelines"
                                            placeholder="Select Pipeline"
                                        >
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="pipeline" class="text-danger" />
                            </div>
                        </div>
                        <schedule-component :schedule-data="form" title="pipeline" :close-modal="closeModal" />
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Removing' : 'Remove' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'RemovePipeline',

        data () {
            return {
                form: {
                    contacts: '',
                    pipeline_id: [],
                    action: '/pipeline/contacts-remove'
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            contacts () {
                const vm = this;

                vm.form.contacts = vm.contacts;
            },

            modelValue (value) {
                const vm = this;

                if (value == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.pipelines.length == 0) {
                    vm.getAllPipelines();
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent
        },

        computed: {
            ...mapState({
                pipelines: state => state.pipelineModule.allPipelines,
                stages: state => state.pipelineModule.stages,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllPipelines: 'pipelineModule/getAllPipelines',
                removeContact: 'pipelineModule/removeContact',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts: '',
                    pipeline_id: [],
                    action: '/pipeline/contacts-remove'
                };
            },

            handleRemoveContactPipeline ( parmas, { setFieldError } ) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to remove selected contacts from pipeline?")
                option.preConfirm = function() { return vm.removeContact(vm.form) };

                Swal.fire(option);
            },
        },
    }
</script>
